import React from "react";
import {Button, ButtonRow, PageState, WarningIcon, Text, ErrorIcon, Card} from "@myob/myob-widgets";
import "./Common.css"
import notFound from "../assets/no-results-found.svg"
import somethingWrong from "../assets/something-went-wrong.svg"

function CustomTitle(text,type="error") {
  return (<div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'left'
  }}>
    {type === "warning"
      ? <WarningIcon color="warning" style={{marginRight: 8}} />
      : <ErrorIcon color="danger" style={{marginRight: 8}}/>}
    {text}
  </div>);
}

function ErrorTitle(text) {
  return (<div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'left'
  }}>
    <ErrorIcon color="danger" style={{marginRight: 8}} />{text}
  </div>);
}

function ErrorView({type, redirectUrl}) {

  const handleCancel = () => {
    window.location.href = redirectUrl;
  }

  const errorTypes = {
    notFound: {
      title: "Page Not Found",
      description: 'This page doesn\'t exist',
      img: notFound,
      alt: "page not found"
    },
    somethingWentWrong: {
      title: "Something went wrong",
      description: "If the issue persists, contact MYOB support",
      img: somethingWrong,
      alt: "something went wrong",
    },
    applicationFailed: {
      title: "Application failed",
      description: "If the issue persists, contact MYOB support",
      img: somethingWrong,
      alt: "application failed",
    },
    applicationResultFailed: {
      title: "Page Not Found",
      description: 'This page doesn\'t exist',
      img: notFound,
      alt: "page not found",
    },
    finalisationFailed: {
      title: "Finalisation failed",
      description: "If the issue persists, contact MYOB support",
      img: somethingWrong,
      alt: "finalisation failed",
    },
    openBankingRedirectError: {
      title: "Unable to connect accounts",
      description: "We're unable to connect your bank accounts right now. Please try again later.",
      img: somethingWrong,
      alt: "open banking redirect error",
    },
    openBankingOptOutError: {
      title: ErrorTitle("Accounts not connected"),
      description: (
        <div style={{paddingLeft: 8}}>
          <Text textAlign="center" style={{marginBottom: 16}}> By exiting early we couldn't connect your accounts.
            To compete your application, return and complete all steps.</Text>
          <Text textAlign="center">To go back to MYOB, close this browser window</Text>
        </div>
      ),
    },
    openBankingUsrDhNotListedError: {
      title: CustomTitle("Bank feeds not available for this bank", "warning"),
      description: (
        <div style={{paddingLeft: 8}}>
          <Text textAlign="center" style={{marginBottom: 16}}>To connect bank feeds, choose a bank from the list
            provided.</Text>
          <Text textAlign="center">To go back to MYOB, close this browser window</Text>
        </div>
      ),
    },
    consentManagementErrorView: {
      title: CustomTitle("Something went wrong", "error"),
      description: (
        <div style={{minWidth: "50.4rem"}}>
          <Text textAlign="center">Please try again later. To go back to MYOB, close this browser window</Text>
        </div>
      ),
    },
    openBankingFinaliseError: {
      title: "Unable to load feeds",
      description: "Your accounts are connected, but we're unable to show your transactions right now. Please try again later.",
      img: somethingWrong,
      alt: "open banking finalise error",
    },
  }

  return (
    <div className={'page-block'}>
        <Card>
          <PageState
            className={'img-block'}
            title={errorTypes[type].title}
            image={<img src={errorTypes[type].img}
            alt={errorTypes[type].alt} />}
            description={
              <React.Fragment>
                {errorTypes[type].description}
              </React.Fragment>
            }
          >
          </PageState>
      </Card>
      { redirectUrl && (
        <ButtonRow className="animate">
        <Button
          className="redirect-from-error-page"
          style={{marginBottom:'20px'}}
          onClick={handleCancel}
        >
          Go back to Manage Bankfeeds
        </Button>
      </ButtonRow> )}
    </div>
  );
}

export default ErrorView;
