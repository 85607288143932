import FeedConfirmationView from "../FeedConfirmationView";
import OpenBankingFeedConfirmationView from "../OpenBankingFeedConfirmationView";
import * as _ from "lodash";
import React from "react";
import OpenBankingAccountSummary from "./components/OpenBankingAccountSummary";
import {FullStory} from "@fullstory/browser";

const FeedConfirmationResultContainer = ({source,
                                           areAllAccountsOnboarded,
                                           accountsNotYetOnboarded,
                                           redirectUrl,
                                           selectedOnboardAccounts
                                         }) => {
  const getOpenBankingSummarySection = () => {
    if (areAllAccountsOnboarded) {
      FullStory('trackEvent', {
        name: 'Onboard Finalise Confirmation',
        properties: {
          status: "alreadyOnboarded",
          source: source
        },
      })
      return <OpenBankingAccountSummary resultStatus={"alreadyOnboarded"}/>
    }
    const unselectedAccounts = accountsNotYetOnboarded.filter(account => !selectedOnboardAccounts.includes(account));
    if (!_.isEmpty(unselectedAccounts)) {
      FullStory('trackEvent', {
        name: 'Onboard Finalise Confirmation',
        properties: {
          status: "selectedSuccess",
          source: source
        },
      })
      return <>
        <OpenBankingAccountSummary accounts={selectedOnboardAccounts} resultStatus={"selected"}/>
        <OpenBankingAccountSummary accounts={unselectedAccounts} resultStatus={"unselected"}/>
      </>
    }
    FullStory('trackEvent', {
      name: 'Onboard Finalise Confirmation',
      properties: {
        status: "allSuccess",
        source: source
      },
    })
    return <OpenBankingAccountSummary accounts={selectedOnboardAccounts} resultStatus={"allSuccess"}/>
  }

  if (source?.toLowerCase() === "OpenBanking".toLowerCase()) {
    return (
      <OpenBankingFeedConfirmationView
        summarySection={getOpenBankingSummarySection()}
        redirectUrl={redirectUrl}
      />
    );
  }
  return (
    <FeedConfirmationView
      accountData={selectedOnboardAccounts}
      redirectUrl={redirectUrl}
    />
  );
}

export default FeedConfirmationResultContainer;
