import React from "react";
import '../../../../../common/AccountPageStateStyle.css';
import {InfoIcon, PageState, SuccessIcon, WarningIcon} from "@myob/myob-widgets";

const OpenBankingAccountSummaryPageState = ({resultStatus, selectedAccountsNumber}) => {
  const resultStatuses = {
    alreadyOnboarded: {
      title: (
        <div className="alternate-title-container">
          <InfoIcon color="brand" style={{marginRight: '8px'}}/>
          <span>Accounts already connected</span>
        </div>
      ),
      description: (
        <div className="alternate-description-container">
          <p>
            The selected accounts are already connected to MYOB
          </p>
        </div>
      ),
    },
    allSuccess: {
      title: (
        <div className="title-container">
          <SuccessIcon color="success" style={{marginRight: '8px'}}/>
          <span>Connection successful</span>
        </div>
      ),
      description: (
        <div className="description-container">
          <p>
            These accounts are now connected to MYOB
          </p>
        </div>
      ),
    },
    selected: {
      title: (
        <div className="title-container">
          <SuccessIcon color="success" style={{marginRight: '8px'}}/>
          <span>{`${selectedAccountsNumber} account(s) connected`}</span>
        </div>
      ),
      description: (
        <div className="description-container">
          <p>
            These accounts are now connected to MYOB
          </p>
        </div>
      ),
    },
    unselected: {
      title: (
        <div className="title-container">
          <WarningIcon color="warning" style={{marginRight: '8px'}}/>
          <span>Accounts not connected</span>
        </div>
      ),
      description: (
        <div className="description-container">
          <p>
            You've reached the maximum of 2 connected accounts.
            We will not save data for these accounts.
          </p>
        </div>
      ),
    },
  }

  return (
    <PageState
      title={resultStatuses[resultStatus].title}
      className='img-block'
      description={resultStatuses[resultStatus].description}
      image={resultStatuses[resultStatus].img}
    />
  );
};

export default OpenBankingAccountSummaryPageState;
