import ErrorView from "../../common/ErrorView";
import React, {useEffect} from "react";
import {FullStory} from "@fullstory/browser";

function OpenBankingRedirectErrorView({errorCode: error}) {
  useEffect(() => {
    FullStory('trackEvent', {
      name: 'Open Banking Redirect Error',
      properties: {
        error_code: error
      },
    })
  }, []);
  if (error === 'usr_opt_out') {
    return (
      <ErrorView type='openBankingOptOutError' />
    );
  }
  if (error === 'usr_dh_not_listed') {
    return (
      <ErrorView type='openBankingUsrDhNotListedError' />
    );
  }
  return (
    <ErrorView type='openBankingRedirectError' />
  );
}

export default OpenBankingRedirectErrorView;
